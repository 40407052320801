import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import DynamicImage from "../dynamic-image"

const Card = styled(props => <Link {...props} />)`
  width: 680px;
  height: 172px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
`

const ImageContainer = styled.div`
  height: 100%;
  width: 172px;
  display: flex;
`

const TextContainer = styled.div`
  width: calc(100% - 172px);
  padding: 23px 37px 28px 23px;
`

const Title = styled.p`
  max-height: 72px;
  max-width: 280px;
  color: #222222;
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CompanyName = styled.p`
  height: 19px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #222222;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 4px;
`

const Description = styled.p`
  color: #222222;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Tag = styled.span`
  display: inline-block;
  /* height: 24px; */
  padding: 0px 12px 0px 12px;
  margin-right: 4px;
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  background-color: #efefef;
  /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15); */
`

const LongCaseCard = props => {
  const cardData = props.cardData
  const slug = props.slug
  const tags = props.tags
  // const key = props.key
  const title = cardData.title
  const companyName = cardData.companyName
  const description = cardData.description
  const image = cardData.image

  return (
    <Card className="case" to={`/case/${slug}`}>
      <ImageContainer>
        <DynamicImage image={image} />
      </ImageContainer>
      <TextContainer>
        <CompanyName>{companyName}</CompanyName>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {
          tags.map(
            tag => {
              const name = tag.name
              const key = tag.contentful_id
              return <Tag key={key}>{name}</Tag>
            }
          )
        }
      </TextContainer>
    </Card>
  )
}

export default LongCaseCard
