import styled from "@emotion/styled"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import React from "react"
import { useEffect, useState } from "react"
import CasestudyLink from "../toppage/casestudy-link"
import CardForShowMore from "./card-for-showmore"
import CardNotFound from "./card-notfound"
import LongCaseCard from "./long-case-card"

const Container = styled.div`
  /* margin: 0 -1.0875rem;
  padding: 40px 24px 85px 24px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 713px) {

  }
`
const Cases = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  max-width: 1056px;
  .case {
    margin-bottom: 24px
  }
  .case::last-child {
    margin: 0;
  }
  @media screen and (max-width: 713px) {
    width: 100%;
  }
`

const CaseStudies = props => {
  const allDevelopmentData = props.data
  const industry = props.industry
  const objective = props.objective

  const [numberOfDisplays, setNumberOfDisplays] = useState(5)
  const [filteredDevelopmentData, setFilteredDevelopmentData] = useState([])
  const [notfound, setNotfound] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)

  useEffect(() => {
    if(!industry && !objective) {
      setFilteredDevelopmentData(allDevelopmentData)
    }
    if (industry || objective) {
      setFilteredDevelopmentData([])
      setNumberOfDisplays(5)
      setIsFiltered(true)
      let results = []

      if (industry && !objective) {
        results = filterByIndustry(industry)
      } else if (!industry && objective) {
        results = filteredByObjective(objective)
      } else if (industry && objective) {
        const resultsByIndustry = filterByIndustry(industry)
        const resultsByObjective = filteredByObjective(objective)
        results = intersect(resultsByIndustry, resultsByObjective)
      }
      if (results.length) {
        setNotfound(false)
      }
      if (!results.length) {
        setNotfound(true)
      }
      setFilteredDevelopmentData(results)
    }
  }, [industry, objective])

  const showMoreCard = () => {
    setNumberOfDisplays(numberOfDisplays + 6)
  }

  const filterByIndustry = industry => {
    const results = []

    allDevelopmentData.forEach(element => {
      const node = element.node
      if (node.customerIndustry === industry) {
        results.push(element)
      }
    })
    return results
  }

  const filteredByObjective = objective => {
    const results = []

    allDevelopmentData.forEach(element => {
      const node = element.node
      if (node.developmentObjective === objective) {
        results.push(element)
      }
    })
    return results
  }

  const intersect = (resultsByIndustry, resultsByObjective) => {
    const results = []

    resultsByIndustry.forEach(element1 => {
      const contentfulIds = results.map(result => {
        return result?.node?.contentful_id
      })
      const contentfulId1 = element1.node.contentful_id

      resultsByObjective.forEach(element2 => {
        const contentfulId2 = element2.node.contentful_id
        if (
          contentfulId1 === contentfulId2 &&
          !contentfulIds.includes(contentfulId1)
        ) {
          results.push(element1)
        }
      })
    })
    return results
  }

  const getDevelopmentData = () => {
    if (!isFiltered) {
      return allDevelopmentData
    }
    if (isFiltered && !notfound) {
      return filteredDevelopmentData
    }
  }

  const developmentData = getDevelopmentData()

  const isWide = useMediaQuery("(min-width: 712px)", { noSsr: false })

  return (
    <Container>
      <Cases>
        {developmentData?.length &&
          developmentData.map((data, index) => {
            const dataNode = data.node
            const cardData = dataNode.developmentCaseCard
            const slug = dataNode.slug
            const tags = dataNode.metadata.tags
            const key = dataNode.contentful_id
            const caseCard = isWide ? (
              <LongCaseCard
                cardData={cardData}
                slug={slug}
                tags={tags}
                key={key}
              />
            ) : (
              <CaseCard cardData={cardData} slug={slug} tags={tags} key={key} />
            )
            // const caseCard = <CaseCard cardData={cardData} slug={slug} tags={tags} key={key} />
            return <>{index + 1 <= numberOfDisplays && caseCard}</>
          })}
        {developmentData?.length &&
          developmentData.length >= numberOfDisplays && (
            <CardForShowMore
              showMoreCard={() => showMoreCard()}
              isWide={isWide}
            />
          )}
        {!developmentData?.length && <CardNotFound />}
      </Cases>
    </Container>
  )
}

const CaseCard = props => {
  const cardData = props.cardData
  const slug = props.slug
  const tags = props.tags
  const key = props.key

  return (
    <div className="case">
      <CasestudyLink cardData={cardData} slug={slug} tags={tags} key={key} />
    </div>
  )
}

export default CaseStudies
