import React from "react"
import styled from "@emotion/styled"

const CardNotFound = () => {
  const Card = styled.div`
    height: 172px;
    width: 680px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 12px 24px 12px;
    @media screen and (max-width: 713px) {
      height: 386px;
      width: 328px;
    }
  `
  const Text = styled.h1`
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  `

  return (
    <Card>
      <Text>該当する事例がありません。</Text>
    </Card>
  )
}

export default CardNotFound
