import React from "react"
import styled from "@emotion/styled"
import ArrowForwardIcon from "@material-ui/icons/KeyboardArrowRight"
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"

const Card = styled.div`
  height: 172px;
  width: 680px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px 24px 12px;
  @media screen and (max-width: 713px) {
    height: 386px;
    width: 328px;
  }
`

const ShowMoreButton = styled.button`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #ffffff;
  svg {
    width: 24px;
    height: 24px;
  }
`

const CardForShowMore = (props) => {
  const showMoreCard = () => {
    props.showMoreCard()
  }
  const isWide = props.isWide

  return (
    <Card>
      <ShowMoreButton onClick={() => showMoreCard()}>
        その他の事例
        {
          isWide && <ArrowDownIcon />
        }
        {
          !isWide && <ArrowForwardIcon />
        }
        {/* <StaticImage
          src="../../images/chevron_right_24px_rounded.png"
          alt="もっと事例をみる"
          width={24}
        /> */}
      </ShowMoreButton>
    </Card>
  )
}

export default CardForShowMore
