import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { UsePageContext } from "../context/page-context"
import { useState } from "react"
import styled from "@emotion/styled"
import CaseStudies from "../components/development-case/case-studies"

const Root = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0 100px 0;
`

const Container = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 713px) {
    width: 100%;
  }
`

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 39px;
  width: 100%;
`

const Header = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .industry {
    margin-right: 16px;
  }
  @media screen and (max-width: 713px) {
    width: 100%;
    align-items: center;
  }
`

const SelectContainer = styled.div`
  display: flex;
  width: 100%;
`

const Select = styled.select`
  display: flex;
  align-items: center;
  padding: 0 12px 0 16px;
  box-sizing: border-box;
  height: 36px;
  width: 215px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 14px;
`

const PlaceHolder = styled.option`
  height: 14px;
  width: 122px;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 14px;
  /* display: none; */
`

const Option = styled.option`
  height: 14px;
  width: 122px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 14px;
`

const Cases = ({ data }) => {
  const { setIsAtContactPage, setIsAtNotfoundPage } = UsePageContext()
  useState(() => {
    setIsAtContactPage(false)
    setIsAtNotfoundPage(false)
  })

  const [industry, setIndustry] = useState(null)
  const [objective, setObjective] = useState(null)

  const changeIndustry = event => {
    const input = event.target.value
    setIndustry(input)
  }

  const changeObjective = event => {
    const input = event.target.value
    setObjective(input)
  }

  const cases = data.allContentfulDevelopmentCase.edges

  let industries = cases.map(element => {
    const node = element.node
    return node.customerIndustry
  })
  industries = Array.from(new Set(industries))

  let objectves = cases.map(element => {
    const node = element.node
    return node.developmentObjective
  })
  objectves = Array.from(new Set(objectves))

  return (
    <>
      <Seo
        title="事例"
        description="株式会社アクアが開発をおこなった事例の一覧です。"
      />
      <Root>
        <Container>
          <Header>
            <Title>事例集</Title>
            <SelectContainer>
              <Select
                className="industry"
                onChange={event => changeIndustry(event)}
              >
                <PlaceHolder value="">業種</PlaceHolder>
                {industries.map((industry, index) => {
                  return (
                    <Option key={index} value={industry}>
                      {industry}
                    </Option>
                  )
                })}
              </Select>
              <Select
                className="objective"
                onChange={event => changeObjective(event)}
              >
                <PlaceHolder value="">開発目的</PlaceHolder>
                {objectves.map((objectve, index) => {
                  return (
                    <Option key={index} value={objectve}>
                      {objectve}
                    </Option>
                  )
                })}
              </Select>
            </SelectContainer>
          </Header>
          <CaseStudies data={cases} industry={industry} objective={objective} />
        </Container>
      </Root>
    </>
  )
}

export const query = graphql`
  query CasesQuery {
    allContentfulDevelopmentCase(sort: { fields: displayOrder, order: ASC }) {
      edges {
        node {
          contentful_id
          slug
          developmentObjective
          customerIndustry
          developmentCaseCard {
            title
            companyName
            description
            image {
              gatsbyImageData
            }
          }
          metadata {
            tags {
              name
              contentful_id
              id
            }
          }
        }
      }
    }
  }
`

export default Cases